/**
 * Attendre que le CSS soit chargé dans le DOM avant de révéler le corps de la page
 */
document.onreadystatechange = () => {
  if (document.readyState === "complete") {
    document.body.style.visibility = "visible";
  }
};

/**
 * Required CSS
 */
require("../scss/style.scss");

/**
 * Required components
 */
require("bootstrap/dist/js/bootstrap.bundle.js");
require("@odw/lnc-web-components/main-es2015.js");
require("@odw/lnc-web-components/polyfills-es2015.js");
require("@odw/lnc-web-components/runtime-es2015.js");
