/**
 * Class service pour le parcours didactique
 */
export default class ParcoursService {
  constructor(elements) {
    this.elements = elements;
    this.flag = false;
  }

  /**
   * Arrêter la fonction animateBackdrop
   * @param {boolean} flagValue
   */
  setFlag(flagValue) {
    this.flag = flagValue;
  }

  /**
   * Zoom sur l'encart tutoriels et ces composants
   * @param {string} decision
   */
  zoomTutoriel(decision) {
    if (decision === "zoom") {
      this.elements.backdrop.classList.add("zoom-encart-tutoriels");
      this.elements.encartTutoriels.classList.add("zoom-encart-tutoriels");
      this.elements.textTutoriels.classList.add("font-size-tutoriels");
      this.elements.buttonTutoriels.classList.add("font-size-tutoriels");
      this.elements.iconButtonTutoriels.classList.add("zoom-icon");
      this.elements.iconTutoriels.classList.add("zoom-icon");
    } else if (decision === "nozoom") {
      this.elements.backdrop.classList.remove("zoom-encart-tutoriels");

      this.elements.encartTutoriels.classList.remove("zoom-encart-tutoriels");
      this.elements.textTutoriels.classList.remove("font-size-tutoriels");
      this.elements.buttonTutoriels.classList.remove("font-size-tutoriels");
      this.elements.iconButtonTutoriels.classList.remove("zoom-icon");
      this.elements.iconTutoriels.classList.remove("zoom-icon");
    }
  }

  /**
   * Mettre le focus de la backdrop sur un élément
   * @param {DOMRect} position
   * @param {string} backdropSteps
   */
  _movebackdropTo(position) {
    if (position) {
      this.elements.backdrop.style.setProperty("--before-top", position.top.toString() + "px");
      this.elements.backdrop.style.setProperty("--before-left", position.left.toString() + "px");
      this.elements.backdrop.style.setProperty("--before-bottom", position.bottom.toString() + "px");
      this.elements.backdrop.style.setProperty("--before-right", position.right.toString() + "px");
      this.elements.backdrop.style.setProperty("--before-width", position.width.toString() + "px");
      this.elements.backdrop.style.setProperty("--before-height", position.height.toString() + "px");
    } else {
      this.elements.backdrop.style.setProperty("--before-top", "0px");
      this.elements.backdrop.style.setProperty("--before-left", "0px");
      this.elements.backdrop.style.setProperty("--before-bottom", "0px");
      this.elements.backdrop.style.setProperty("--before-right", "0px");
      this.elements.backdrop.style.setProperty("--before-width", "0px");
      this.elements.backdrop.style.setProperty("--before-height", "0px");
    }
  }

  /**
   * Adapter le focus du backdrop au zoom de l'encart (étape 13)
   * @param {HTMLElement} toAnimate
   */
  animateBackdrop(toAnimate) {
    setTimeout(() => {
      if (this.flag) {
        return;
      }
      this._movebackdropTo(toAnimate.getBoundingClientRect());
      setTimeout(() => {
        this.animateBackdrop(toAnimate);
      }, 0);
    }, 0);
  }

  /**
   * Déplacer le bouton close selon les étapes
   * @param {string} steps
   * @param elementNav
   */
  _moveCloseButton(steps, elementNav) {
    if (steps === "step_clicknav_and_header") {
      this.elements._closeButton.style.top = 140 + "px";
      this.elements._closeButton.style.right = "20px";
      this.elements._closeButton.style.float = "right";
    } else if (steps === "steps_jaiCompris_and_afterHeader") {
      this.elements._closeButton.style.top = "13px";
      this.elements._closeButton.style.right = "20px";
      this.elements._closeButton.style.float = "right";
    } else if (steps === "step11") {
      this.elements._closeButton.style.top = "13px";
      this.elements._closeButton.style.right = (window.innerWidth - parseInt(elementNav.x) + 20).toString() + "px";
      this.elements._closeButton.style.float = "left";
    }
  }
}
