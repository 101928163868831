import { CookiePortail } from "../../js/services/cookie-portail";
import { StoreKeys } from "../../js/services/enums";
import { StoreManager } from "../../js/services/store-manager";
import ModalPremiereConnexion from "./modal-premiere-connexion";
import { onClickParcours, startParcours } from "./parcours-guide";

import { ComponentHelper } from "../../js/services/component-helper";
import { PageManager } from "../../js/services/page-manager";
import { PageTypes } from "../../js/services/settings-loader";
import { TagCommanderManager } from "../../js/services/tag-commander-manager";
import {
  CHARGEMENT_POPIN_PREMIERE_CONNEXION,
  CLICK_ACCEDER_TUTORIELS,
  CLICK_ACCEDER_VOS_COMPTEURS,
  CLICK_ACCEDER_VOS_MESURES,
  CLICK_AIDE_ET_CONTACT,
  CLICK_POPIN_PREMIERE_CONNEXION_LANCER_PARCOURS_GUIDE,
  CLICK_POPIN_PREMIERE_CONNEXION_PAS_MAINTENANT,
  PARCOURS_GUIDE,
} from "../../js/tag-plan";

import { neufsneufSettings } from "../../js/services/settings-loader";

require("./parcours-guide");
require("../require");
require("./page.scss");

customElements.define("modal-premiere-connexion", ModalPremiereConnexion, { extends: "div" });

/**
 * Abonnement au Store Manager pour récupérer les habilitations lorsqu'elles sont prêtes
 */
StoreManager.subscribe(StoreKeys.HABILITATIONS, onHabilitationsReady);

function premiereConnexion(dateAffichageMaxPopup) {
  if (new Date() < dateAffichageMaxPopup) {
    chargement_popin();
    document.getElementById("overlay").style.display = "block";
    ComponentHelper.openModal("#modal-premiere-connexion");
    document.getElementById("annuler").addEventListener("click", annulerModal);
    document.getElementById("valider").addEventListener("click", validerModal);
    document.getElementById("annuler-mobile").addEventListener("click", annulerModal);

    window.addEventListener("click", function (e) {
      if (document.getElementById("modal-container").contains(e.target)) {
        //click a l'interieur de la modal
      } else if (document.getElementById("modal-premiere-connexion").contains(e.target)) {
        //click a l'exterieur de la modal
        closeModal();
      }
    });
  }
}

function closeModal() {
  document.getElementById("overlay").style.display = "none";
  jQuery("#modal-premiere-connexion").modal("hide");
  CookiePortail.set("checkPremierAffichage", true);
}

function annulerModal() {
  onClick_popin_pasMaintenant();
  closeModal();
}

function validerModal() {
  onClick_popin_lancerParcours();
  closeModal();
}

Date.prototype.addDays = function (days) {
  let date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

function onHabilitationsReady() {
  let datePremierRattachement = new Date();

  StoreManager.habilitations.forEach((hab) => {
    let dateCreation = new Date(hab.dateCreation);
    if (dateCreation < datePremierRattachement) {
      datePremierRattachement = dateCreation;
    }
  });
  let dateAffichageMaxPopup = datePremierRattachement.addDays(30);

  if (!CookiePortail.get("checkPremierAffichage")) {
    premiereConnexion(dateAffichageMaxPopup);
  }
}

/**
 * Feature flipping du widget mesures sur la page d'accueil
 */
if (!neufsneufSettings.feature_flipping.afficher_widget_mesures_accueil) {
  document.querySelector("#encart-vos-mesures").style.display = "none";
}

/**
 * Abonnement au clic du bouton du lnc-illustrative-feedback
 */
document
  .querySelector(".encart-accueil #bouton-acceder-vos-compteurs")
  ?.addEventListener("click", redirectToVosCompteurs);

document
  .querySelector(".encart-accueil #bouton-acceder-visualiser-mesures")
  ?.addEventListener("click", redirectToVisualiserMesures);

document.getElementById("bouton-tutoriels")?.addEventListener("click", redirectToTutoriels.bind(this));
document.getElementById("bouton-aide-et-contact")?.addEventListener("click", redirectToAideEtContact.bind(this));

if (document.getElementById("img-parcours")) {
  document.getElementById("img-parcours").onclick = function () {
    onClickParcours();
  };
}

//TagCommander
function onClick_popin_lancerParcours() {
  TagCommanderManager.tagClick(CLICK_POPIN_PREMIERE_CONNEXION_LANCER_PARCOURS_GUIDE);
  TagCommanderManager.tagPage(PARCOURS_GUIDE);
  startParcours();
}
function onClick_popin_pasMaintenant() {
  TagCommanderManager.tagClick(CLICK_POPIN_PREMIERE_CONNEXION_PAS_MAINTENANT);
}
function chargement_popin() {
  TagCommanderManager.tagPage(CHARGEMENT_POPIN_PREMIERE_CONNEXION);
}
function redirectToTutoriels() {
  TagCommanderManager.tagClick(CLICK_ACCEDER_TUTORIELS);
}

function redirectToVosCompteurs() {
  TagCommanderManager.tagClick(CLICK_ACCEDER_VOS_COMPTEURS);
  PageManager.redirectTo(PageTypes.VOS_COMPTEURS);
}

function redirectToVisualiserMesures() {
  TagCommanderManager.tagClick(CLICK_ACCEDER_VOS_MESURES);
  PageManager.redirectTo(PageTypes.VISUALISER_VOS_MESURES);
}

function redirectToAideEtContact() {
  TagCommanderManager.tagClick(CLICK_AIDE_ET_CONTACT);
}
