import ModalBase from "../../js/components/modal/modal-base";
import { ComponentHelper } from "../../js/services/component-helper";

/**
 * La modal de confirmation d'annulation de saisie en cours
 */
export default class ModalAnnulationSaisie extends ModalBase {
  static pymjsData = {};

  /**
   * Initialise une nouvelle instance de type {@type ModalAnnulationSaisie}.
   */
  constructor() {
    super();
  }

  /**
   * Action pour réagir au clic "Annulation de saisie".
   * @override
   */
  _onAccept() {
    if (ModalAnnulationSaisie.pymjsData.action === "ajoutCanal") {
      ComponentHelper.pymSendMessage("callbackAjoutCanal", JSON.stringify({ action: "accept" }));
    } else if (ModalAnnulationSaisie.pymjsData.action === "changeCanal") {
      ComponentHelper.pymSendMessage("callbackChangeCanal", JSON.stringify({ action: "accept" }));
    } else if (ModalAnnulationSaisie.pymjsData.action === "changeSiren") {
      ComponentHelper.pymSendMessage("callbackChangeSiren", JSON.stringify({ action: "accept" }));
    } else if (ModalAnnulationSaisie.pymjsData.action === "changeCanalType") {
      ComponentHelper.pymSendMessage("callbackChangeCanalType", JSON.stringify({ action: "accept" }));
    }

    this._closeModal();
  }

  /**
   * Action pour réagir au clic "Fermer".
   * @override
   */
  _onDismiss() {
    if (ModalAnnulationSaisie.pymjsData.action === "changeCanal") {
      ComponentHelper.pymSendMessage("callbackChangeCanal", JSON.stringify({ action: "decline" }));
    } else if (ModalAnnulationSaisie.pymjsData.action === "changeSiren") {
      ComponentHelper.pymSendMessage("callbackChangeSiren", JSON.stringify({ action: "decline" }));
    }
    this._closeModal();
  }
}
