import { NeufsneufModal } from "../../js/components/common/neufsneuf-modal";
import { ComponentHelper } from "../../js/services/component-helper";

export default class ModalConfirmationSuppression extends NeufsneufModal {
  /**
   * Le canal de contact
   * @type {string}
   */
  canalContact = "";
  /**
   * Texte utilisé dans la modale quand le canal de contact est un mail
   * @type {string}
   */
  mailDeContact = "l’email de contact";
  /**
   * Texte utilisé dans la modale quand le canal de contact est un téléphone (fixe ou mobile)
   * @type {string}
   */
  telephoneDeContact = "le numéro de téléphone";

  /**
   * Initialise une nouvelle instance de type {@type ModalConfirmationSuppression}.
   */
  constructor() {
    super("modal-confirmation-suppression");

    this.onAccept(() => {
      ComponentHelper.pymSendMessage("confirmation-suppression-canal", this.canalContact);
    });
  }

  /**
   * Méthode écran afin de mettre le bon titre et texte dans la modale
   *
   * @param {string[]} data - Tableau de deux éléments contenant en 0 le type de canal de contact et en 1 le canal de contact
   */
  show(data) {
    const title = document.querySelector("#modal-confirmation-suppression #modal-title");
    title.innerHTML = `Supprimer ${data[0] === "mail" ? this.mailDeContact : this.telephoneDeContact}`;

    const text = document.querySelector("#modal-confirmation-suppression [body] p");
    text.innerHTML = `Vous vous apprêtez à supprimer ${
      data[0] === "mail" ? this.mailDeContact : this.telephoneDeContact
    } : <strong>${data[1]}</strong>.`;

    this.canalContact = data[1];

    super.show();
  }
}
