/**
 * Required Theme
 */
require("./require-theme.js");

/**
 * Required Javascript
 */
require("@ungap/custom-elements");
require("../js/components/declare-components.js");
require("../js/app-main.js");
